// 首先引入我们自定义的axios对象
import request from '@/utils/request'
// 导出对象
  export function getinfoList() {
    return request({
      url:'/wwwapi/zchwuliu/news/news2/3/1',
      method: 'get'
    })
  }
  export function getinfodetail() {
    return request({
      url:'/wwwapi/zchwuliu/news/news2/3/1',
      method: 'get'
    })
  }