<template>
    <div>
        <headertop/>
        <div>
          <img style="width:100%" src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/banner (1).png" alt="">
        </div>
        <div class="text">
          <ul>
            <li v-for="(item,index) of informationlist" :key="index" @click="godetail(item.newsid)">
              <b class="gonggao">信息公告</b>
              <h5>{{item.title}}</h5>
              <p>{{item.summary}}<br>{{item.releasedate|dateformat('YYYY-MM-DD')}}</p>
            </li>
          </ul>
        </div>
               <el-pagination
      class="el-pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      align="left"
      style="margin-top: 52px; margin-bottom: 54px"
      :total="this.info"
    >
    </el-pagination>
            <bottombar/>
    </div>
</template>
<script>
import headertop from '@/components/headertop.vue'
import bottombar from '@/components/bottombar.vue'
import {getinfoList} from '@/api/test'
export default {
    components: {
    headertop,
    bottombar
  },
  data() {
      return {
        informationlist:[],
        info:0,
         pageNo: 1,
      pageSize: 10,
      };
    },
    created(){
      this.initData()
    },
   methods: {
    initData() {
     getinfoList().then(res =>{
        console.log(res.data)
        this.informationlist = res.data.data
        if (res.data.data.length > 0) {
            this.info = res.data.data.length;
          } else {
            this.info = 0;
          }
      })
    },
   godetail(newsid) {
     console.log(newsid)

      this.$router.push(
    {
        path:'/details',
        query:{
            newsid
        }
    }
)
    },
     handleSizeChange: function (size) {
      this.pageSize = size;
      this.pageNo = 1;
    },
    handleCurrentChange: function (pageNo) {
      this.pageNo = pageNo;
    },
    },
}
</script>
<style scoped>
.text{
  display: flex;
  justify-content: center;
  align-items: center;
}
.text ul{
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.text ul li{
  height: 192px;
  width: 1200px;
  cursor: pointer;
  border-bottom: 1px solid #CCCCCC;
   background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/形状2.png)no-repeat 79px 73px;
}
.text ul li:hover{
  background:url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/形状21.png)no-repeat 79px 73px;
  background-color: white;
}
.text ul li:hover .gonggao{
  color: white;
}
.text ul li h5{
font-size: 24px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #000000;
margin-top: 54px;
margin-left: 190px;
width: 830px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}
.text ul li p{
font-size: 16px;
font-family: PingFang SC;
font-weight: 400;
color: #666666;
line-height: 30px;
margin-left: 190px;
width: 830px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}
.el-pagination{
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.gonggao{
    position: relative;
    top: 80px;
    left: 90px;
}
</style>